import React from 'react';
import { Link } from 'react-router-dom';
import footStyle from './footer.module.css';

const Footer = () => {
	return (
		<div>
			<div className={footStyle.footer}>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/zakres-uslug">
						zakres usług
					</Link>
				</div>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/o-mnie">
						o mnie
					</Link>
				</div>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/rejestracja-wizyt">
						rejestracja wizyt
					</Link>
				</div>
			</div>
			{/* <div className={footStyle.footer2}>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/zakres-uslug">
						zakres usług
					</Link>
				</div>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/o-mnie">
						o mnie
					</Link>
				</div>
				<div className={footStyle.footItem}>
					<Link className={footStyle.footLink} to="/rejestracja-wizyt">
						rejestracja wizyt
					</Link>
				</div>
			</div> */}
		</div>
	);
};

export default Footer;
