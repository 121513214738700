import React from 'react';

import aboutStyles from './o-mnie.module.css';

const Omnie = () => {
	return (
		<div>
			<div className={aboutStyles.about}>
				<h1 className={aboutStyles.h1}>
					Małgorzata <br /> Jączak-Goździak
				</h1>
				<h3 className={aboutStyles.h3}>specjalista neurologii</h3>
				<h3 className={aboutStyles.h3}>i neurologii dziecięcej</h3>
			</div>

			<div className={aboutStyles.description}>
				<div className={aboutStyles.p}>
					Jestem lekarzem, specjalistą neurologii i&nbsp;neurologii dziecięcej, absolwentką Uniwersytetu Medycznego im.&nbsp;Karola Marcinkowskiego w&nbsp;Poznaniu. Aktualnie pracuję w&nbsp;Katedrze i&nbsp;Klinice Neurologii Wieku Rozwojowego w&nbsp;Uniwersyteckim Szpitalu Klinicznym w Poznaniu. Ponadto przyjmuję pacjentów w&nbsp;poradniach neurologicznych w&nbsp;Poznaniu, w&nbsp;Obornikach i&nbsp;w&nbsp;Kostrzynie.
				</div>
				<div className={aboutStyles.p}>
					W&nbsp;latach 2009-2020 pracowałam w&nbsp;Centrum Medycznym HCP Szpitalu Jana Pawła II w Poznaniu, gdzie ukończyłam specjalizację z&nbsp;neurologii, a&nbsp;następnie prowadziłam pacjentów w&nbsp;poradni przyszpitalnej.
				</div>
				<div className={aboutStyles.p}>
					Doświadczenie zawodowe zdobywałam podczas pracy w&nbsp;szpitalu, w&nbsp;poradniach oraz poprzez udział w&nbsp;licznych badaniach klinicznych (dla pacjentów z&nbsp;Chorobą Parkinsona, z&nbsp;migreną, ze spastycznością poudarową, padaczką i&nbsp;stwardnieniem rozsianym) a&nbsp;także podczas staży zagranicznych m.in we Francji (Centre Hospitalier de Rennes, Szpital St. Antoine w&nbsp;Paryżu) i&nbsp;w&nbsp;Tunezji (Tunis).
				</div>
				<div className={aboutStyles.p}>
					Wiedzę i&nbsp;umiejętności stale poszerzam poprzez aktywny udział w&nbsp;kursach doszkalających oraz w&nbsp; konferencjach krajowych i&nbsp;zagranicznych. Ukończyłam m.in. kurs EEG kurs USG tętnic domózgowych zewnątrzczaszkowych oraz przezczaszkowe, kurs „Wczesna diagnostyka neurorozwojowa uszkodzeń układu nerwowego niemowląt wg Vojty. W&nbsp;2022 roku podczas XI Ogólnopolskich Dni Neurologii Dziecięcej za pracę pt. „Postępująca asymetria półkul mózgu jako objaw radiologiczny, pomocny w&nbsp; diagnostyce neurologicznej” otrzymałam nagrodę za najlepszą prezentację w&nbsp;sesji konkursowej „Młodych Neurologów”.
				</div>
				<div className={aboutStyles.p}>
					Jestem członkiem Polskiego Towarzystwa Neurologicznego, Polskiego Towarzystwa Neurologii Dziecięcej oraz Europejskiej Akademii Neurologii (EAN).
				</div>
			</div>
		</div>
	);
};

export default Omnie;
