import React from 'react';

import zakresStyle from './zakres-uslug.module.css';

const ZakresUslug = () => {
	return (
		<div className={zakresStyle.services}>
			<h1 className={zakresStyle.h1}>Zakres usług</h1>
			<div className={zakresStyle.description}>
				<p className={zakresStyle.p}>
					Zapraszam na konsultacje osoby dorosłe, dzieci i&nbsp;młodzież ze schorzeniami układu nerwowego takimi jak:
				</p>
				<ul>
					<li>
						migreny i&nbsp;bóle głowy
					</li>
					<li>
						padaczka i&nbsp;inne zaburzenia napadowe niepadaczkowe
					</li>
					<li>
						omdlenia i&nbsp;powtarzające się utraty przytomności
					</li>
					<li>
						zaburzenia mowy
					</li>
					<li>
						zaburzenia czynności ruchowych, obniżone napięcie mięśniowe
					</li>
					<li>
						problemy z&nbsp;koordynacją ruchową i&nbsp;zaburzenia równowagi
					</li>
					<li>
						ruchy mimowolne, tiki, zespół Tourette'a
					</li>
					<li>
						mózgowe porażenie dziecięce
					</li>
					<li>
						choroby nerwowo-mięśniowe np. miopatie, miastenia, dystrofie mięśniowe, neuropatie, zaburzenia czucia
					</li>

					<li>
						choroby demielinizacyjne ośrodkowego układu nerwowego np. stwardnienie rozsiane
					</li>
					<li>
						zaburzenia snu
					</li>
					<li>
						problemy z koncentracją i&nbsp;pamięcią, trudności w nauce
					</li>
					<li>
						choroby naczyniowe mózgu: stany po udarze, malformacje naczyniowe, tętniaki itp.
					</li>
					<li>
						choroba Parkinsona i&nbsp;inne choroby układu pozapiramidowego: drżenie samoistne, dystonie i&nbsp;inne
					</li>

					<li>
						zespoły otępienne: choroba Alzheimera, otępienie naczyniopochodne i&nbsp;inne
					</li>
				</ul>
				<p className={zakresStyle.p}>
					Oferuję również badanie USG doppler-duplex tętnic szyjnych i&nbsp;kręgowych.
				</p>
			</div>
		</div>
	);
};

export default ZakresUslug;
