import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ActiveMenuContext } from '../../context/ActiveMenuContext';

import './header.css';
import headBrain from '../../assets/images/head.png';

const Header = () => {
	const [ active, clickHandle ] = useContext(ActiveMenuContext);
	const [ city, setCity ] = useState('w Kostrzynie');

	useEffect(() => {
		let citys = [ 'w Kostrzynie', 'w Poznaniu', 'w Obornikach' ];
		setInterval(() => {
			setCity(citys[Math.floor(Math.random() * citys.length)]);
		}, 2000);
	}, []);

	return (
		<header>
			<div className="container">
				<nav>
					<Link to="/" className={active ? 'navbar-brand active' : 'navbar-brand'}>
						<img
							className="imgHead"
							src={headBrain}
							alt="Specjalista Neurolog Małgorzata Jączak-Goździak"
						/>
					</Link>
					<h1 className={active ? 'nav-title active' : 'nav-title'}>
						neurolog <span>{city}</span>
					</h1>
					<ul className={active ? 'nav-list active' : 'nav-list'}>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/zakres-uslug" className="nav-link">
								Zakres usług
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/o-mnie" className="nav-link">
								O mnie
							</Link>
						</li>
						<li className="nav-item" onClick={clickHandle}>
							<Link to="/rejestracja-wizyt" className="nav-link">
								Kontakt
							</Link>
						</li>
					</ul>
					<div className={active ? 'hamburger active' : 'hamburger'} onClick={clickHandle}>
						<span className="line" />
						<span className="line" />
						<span className="line" />
					</div>
				</nav>
			</div>
		</header>
	);
};

export default Header;
