import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { HelmetProvider } from 'react-helmet-async';
import { ActiveMenuProvider } from './context/ActiveMenuContext';

import './App.css';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Omnie from './components/O-mnie/O-mnie';
import ZakresUslug from './components/Zakres-uslug/Zakres-uslug';
import Kontakt from './components/Kontakt/Kontakt';
import NotMatch from './components/NotMatch/NotMatch';
import Footer from './components/Footer/Footer';

// const helmetContext = {};

const App = () => {
	return (
		<Router>
			<ScrollToTop>
				{/* <HelmetProvider context={helmetContext}> */}
				<ActiveMenuProvider>
					<Header />
					<main>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/zakres-uslug" component={ZakresUslug} />
							<Route path="/o-mnie" component={Omnie} />
							<Route path="/rejestracja-wizyt" component={Kontakt} />
							<Route component={NotMatch} />
						</Switch>
					</main>
					<Footer />
				</ActiveMenuProvider>
				{/* </HelmetProvider> */}
			</ScrollToTop>
		</Router>
	);
};

export default App;
