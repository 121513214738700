import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ActiveMenuContext } from '../../context/ActiveMenuContext';

import homeStyle from './home.module.css';
import headBrain from '../../assets/images/head150.png';

const IndexPage = () => {
	const [active, clickHandle] = useContext(ActiveMenuContext);

	return (
		<div className={homeStyle.hp}>
			<button className={homeStyle.imgBorder} onClick={clickHandle}>
				<img
					className={active ? homeStyle.imgHead : homeStyle.imgHead}
					src={headBrain}
					alt="Specjalista Neurolog Małgorzata Jączak-Goździak"
				/>
			</button>
			<div className={homeStyle.card}>
				<h1 className={homeStyle.h1}>
					Małgorzata<br />Jączak-Goździak
				</h1>
				<h3 className={homeStyle.h3}>specjalista neurologii </h3>
				<h3 className={homeStyle.h3}>i neurologii dziecięcej</h3>
			</div>
			<div className={homeStyle.more}>
				<Link to="/o-mnie" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>o mnie</h4>
						<div className={homeStyle.moreDesc}>
							Jestem lekarzem, specjalistą neurologii i&nbsp;neurologii dziecięcej, absolwentką Uniwersytetu Medycznego im. Karola
							Marcinkowskiego w Poznaniu...
						</div>
						<div className={homeStyle.moreLink}>więcej o mnie</div>
					</div>
				</Link>
				<Link to="/zakres-uslug" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>zakres usług</h4>
						<div className={homeStyle.moreDesc}>
							Zapraszam na konsultacje osoby dorosłe, dzieci oraz młodzież ze schorzeniami układu nerwowego...
						</div>
						<div className={homeStyle.moreLink}>więcej o usługach</div>
					</div>
				</Link>
				<Link to="/rejestracja-wizyt" className={homeStyle.link}>
					<div className={homeStyle.item}>
						<h4>rejestracja wizyt</h4>
						<div className={homeStyle.moreDesc}>
							Pacjentów przyjmuję w gabinetach w&nbsp;Obornikach Wielkopolskich oraz w&nbsp;Kostrzynie
							Wielkopolskim...
						</div>
						<div className={homeStyle.moreLink}>więcej o wizytach</div>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default IndexPage;
